import { Box } from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import PropTypes from "prop-types"
import React from "react"

import { retry } from "helpers/application"

import Image from "./image"
import StyledLink from "./link"
import Text from "./text"

const ArticleMarkdown = loadable(() =>
  retry(() => import("areas/article/components/article-markdown"))
)

const Textarea = ({
  firstElement,
  element,
  lazyload,
  style,
  linkClickData,
  linkProps,
}) => (
  <Box bg={style?.containerBg} contain={element.contain} width="100%">
    <Box {...style}>
      {element.texts.map((text, i) => {
        const firstSubElement = i === 0 && firstElement

        if (text.type === "link") {
          return (
            <StyledLink
              button_type={text.button_type}
              inline_style={text.inline_style}
              key={`textarealink${i}`}
              linkClickData={linkClickData}
              style={text.style}
              type={text.base_style}
              url={text.url}
              {...linkProps}
            >
              {text.text}
            </StyledLink>
          )
        } else if (text.type === "image") {
          return (
            <StyledLink
              aria-label={text?.alt}
              display="block"
              key={`textareaimg${i}`}
              linkClickData={linkClickData}
              type="image"
              url={text.url}
              {...linkProps}
            >
              <Image
                firstElement={firstSubElement}
                image={text}
                lazyload={lazyload}
                linkClickData={linkClickData}
              />
            </StyledLink>
          )
        } else if (text.type === "paragraph") {
          return (
            <ArticleMarkdown key={`paragraph${i}`}>
              {text.content}
            </ArticleMarkdown>
          )
        } else {
          return (
            <Text
              firstElement={firstSubElement}
              key={`text${i}`}
              linkClickData={linkClickData}
              style={text.style}
              text={text}
            />
          )
        }
      })}
    </Box>
  </Box>
)

Textarea.propTypes = {
  element: PropTypes.object,
  firstElement: PropTypes.bool,
  lazyload: PropTypes.bool,
  linkClickData: PropTypes.string,
  linkProps: PropTypes.object,
  style: PropTypes.object,
}

export default Textarea
